import { Temp } from "./components/TempFrontPage/Temp";

function App() {
  return (
    <div>
      <Temp />
    </div>
  );
}

export default App;
