import { Title, Container, Center } from "@mantine/core";
import classes from "./styles.module.css";

export function Temp() {
  return (
    <div className={classes.root}>
      <Center>
        <div className={classes.label}>Coming Soon</div>
        <Title className={classes.title}>
          We are currently working on creating our website. We will be launching
          soon, stay tune!
        </Title>
      </Center>
    </div>
  );
}
